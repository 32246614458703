<template>
   
  <footer class="rodape">
    <div class="container">
      <div class="row">
        <div class="col-sm">
          <h1>
            <i class="ion ion-ios-flash"></i> Acesso Rápido
            <hr>
          </h1>
          <ul>
            <li>
                <Icon icon="ion:caret-forward" color="#0c8" />
                <a target="_blank"
                href="https://www.pgfn.fazenda.gov.br/servicos-e-orientacoes/perguntas-e-respostas-frequentes">Perguntas
                e respostas frequentes (FAQ)</a>
            </li>
            <li>
                <Icon icon="ion:caret-forward" color="#0c8" />
                <a target="_blank"
                href="https://www.pgfn.fazenda.gov.br/servicos-e-orientacoes/servicos-da-divida-ativa-da-uniao-dau">Orientações
                de todos os serviços PGFN</a>
            </li>
            <li>
                <Icon icon="ion:caret-forward" color="#0c8" />
                <a target="_blank" href="http://www.pgfn.fazenda.gov.br/">Site da PGFN</a>
            </li>
          </ul>
        </div>
        <div class="col-sm">
          <h1>
            <i class="ion ion-ios-text"></i>Contato
            <hr>
          </h1>
         
          <ul>
            <li>
                <Icon icon="ion:caret-forward" color="#0c8" />
                <a target="_blank"
                href="https://falabr.cgu.gov.br/publico/Manifestacao/SelecionarTipoManifestacao.aspx?ReturnUrl=%2f">Ouvidoria</a>
            </li>
            
            <li>
              <Icon icon="ion:caret-forward" color="#0c8" />
              <a target="_blank"
                href="https://www.gov.br/pgfn/pt-br/acesso-a-informacao/institucional/unidades_e_responsaveis">Unidades
                da PGFN</a>
            </li>
          </ul>
        </div>       
      </div>
    </div>
    <div class="chancelas">
      <div class="container">
        <div class="row">
          <div class="col-sm text-right" >
            <div class="row">
              <div class="col-4 col-xs-4 col-sm-6 col-md-6 col-lg-6 mb-6">
              </div>
              <div class="col-4 col-xs-4 col-sm-3 col-md-3 col-lg-3 mb-3">
                <img src="@/assets/img/produto_serpro.svg" alt="Produto Serpro" class="logo-serpro">
              </div>
            </div>
          </div>
          <div class="col-sm text-right" >
            <div class="row">
              <div class="col-4 col-xs-4 col-sm-6 col-md-6 col-lg-6 mb-6">
              </div>
              <div class="col-4 col-xs-4 col-sm-3 col-md-3 col-lg-3 mb-3">
                <img src="@/assets/img/logo_pgfn_horizontal_negativa_branco.svg" alt="PGFN" class="logo-pgfn">
              </div>
            </div>
          </div>
          <div class="col-sm text-right" >
            <div class="row">
              <div class="col-4 col-xs-4 col-sm-6 col-md-6 col-lg-6 mb-6">
              </div>
              <div class="col-4 col-xs-4 col-sm-3 col-md-3 col-lg-3 mb-3">
                <img src="@/assets/img/logo_min_economia.svg" alt="Ministério da Economia" class="logo-min-economia">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { Icon } from '@iconify/vue';

export default {
  name: 'Footer',
  components: {
	  Icon,
  },
};
</script>
<style scoped>

hr {
  margin-top: auto;
  left: 0;
  padding-bottom: 0.1px;
  background-color: #6677ff; 
  font-family: 'Nunito Sans', sans-serif;
}	

ul {
  list-style: none;
  padding-left: 0;
  font-family: 'Nunito Sans', sans-serif;
}

li {
  content: "\f284";
  font-family: 'Nunito Sans', sans-serif;
  font-size: .9rem;
  margin-right: 0.5rem;
  vertical-align: middle;
  margin-bottom: 0.5rem;
}

a:link {
  color: white;
  text-decoration: none;
}

/* visited link */
a:visited {
  color: white;
}

/* mouse over link */
a:hover {
  color: white;
  text-decoration: underline;
}

/* selected link */
a:active {
  color: blue;
}

strong {
   font-weight: 400;
}

h1 {
    color: white;
    border-color: rgba(white, .25);
    font-size: .9rem;
    margin-top: 2.8rem;
    margin-bottom: 0.1rem;
    padding-bottom: .25rem;
    text-transform: uppercase;
}

i {
    font-size: 1.2rem;
    color: grey;
    margin-right: 0.3rem;
    vertical-align: bottom;
    font-family: 'Nunito Sans', sans-serif;
}
.rodape {
    background-color: #404040; 
    color:#404040;
    font-display: text-white pt-5 d-print-none
}
.chancelas {
    font-family: "Frutiger", 'Nunito', sans-serif; 
    font-weight: bold;
    padding: 1rem 0;
    text-transform: uppercase;
    background-color: #5466DD; 
}

span {
    display: inline-block;
}

.texto-pgfn {
    display: inline-block;
}

.texto-governo-federal {
    display: block;
    font-family: 'Nunito Sans', sans-serif;
}
  
.logo-pgfn {
  height: 45px;
  margin-right: 1px;
}
  
.logo-min-economia {
  height: 42px;
}

.logo-serpro {
  margin-top: 1rem;
  width:118px;
  height:16px;
  align-items: center;
  align-content: center;
}
  
.titleFoot {
  padding:1px;
  text-align: left;
  margin: 5px 0 0;
  font-size: 2.5rem;
  
}
</style>